@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	youtube
----------*/
$_root: '.c-embed';

.c-embed {
	position: relative;

	&.is-play {
		#{$_root}__poster {
			display: none;
		}
	}

	> video {
		display: block;
		width: 100%;
		height: auto;
	}

	&__poster {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		cursor: pointer;

		> button {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			margin: auto;
			width: 50px;
			height: 50px;
			font-size: 0;
			background: url(/assets/images/icon_play.svg) no-repeat center;
			cursor: pointer;
		}

		> img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--w780 {
		max-width: 780px;
		margin: 0 auto;
	}
}
