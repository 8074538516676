@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	offices-map
----------*/
$_root: '.c-offices-map';

.c-offices-map {
	position: relative;
	width: 100%;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: cover;
	padding-top: 70%;

	// map ベース画像
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-image: url(/assets/images/img_offices_map.png);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: cover;
		z-index: -1;
	}

	&__link {
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		text-indent: -9999px;
		cursor: pointer;

		&--corporate {
			left: calc((728px / 1080px) * 100%);
			top: calc((403px / 780px) * 100%);
			width: calc((258px / 1080px) * 100%);
			height: calc((56px / 780px) * 100%);
		}

		&--kansai {
			left: calc((158px / 1080px) * 100%);
			top: calc((355px / 780px) * 100%);
			width: calc((258px / 1080px) * 100%);
			height: calc((56px / 780px) * 100%);
		}

		&--kyushu {
			left: calc((80px / 1080px) * 100%);
			top: calc((464px / 780px) * 100%);
			width: calc((258px / 1080px) * 100%);
			height: calc((56px / 780px) * 100%);
		}

		&--hokkaido {
			left: calc((423px / 1080px) * 100%);
			top: calc((57px / 780px) * 100%);
			width: calc((258px / 1080px) * 100%);
			height: calc((56px / 780px) * 100%);
		}

		&--tokai {
			left: calc((581px / 1080px) * 100%);
			top: calc((574px / 780px) * 100%);
			width: calc((258px / 1080px) * 100%);
			height: calc((56px / 780px) * 100%);
		}
	}

	@include m.mq-pc {
		&__link {
			display: block;

			&--corporate {
				left: 728px;
				top: 403px;
				width: 258px;
				height: 56px;

				.l-structure--double & {
					left: 607px;
					top: 336px;
					width: 215px;
					height: 47px;
				}
			}

			&--kansai {
				left: 158px;
				top: 355px;
				width: 258px;
				height: 56px;

				.l-structure--double & {
					left: 132px;
					top: 295.7px;
					width: 215px;
					height: 47px;
				}
			}

			&--kyushu {
				left: 80px;
				top: 464px;
				width: 258px;
				height: 56px;

				.l-structure--double & {
					left: 68px;
					top: 386px;
					width: 215px;
					height: 47px;
				}
			}

			&--hokkaido {
				left: 423px;
				top: 57px;
				width: 258px;
				height: 56px;

				.l-structure--double & {
					left: 352px;
					top: 47px;
					width: 215px;
					height: 47px;
				}
			}

			&--tokai {
				left: 581px;
				top: 574px;
				width: 258px;
				height: 56px;

				.l-structure--double & {
					left: 484px;
					top: 478px;
					width: 215px;
					height: 47px;
				}
			}
		}

		&:has(.c-offices-map__link--corporate:hover) {
			background-image: url(/assets/images/img_offices_map_corporate_hv.png);
		}

		&:has(.c-offices-map__link--kansai:hover) {
			background-image: url(/assets/images/img_offices_map_kansai_hv.png);
		}

		&:has(.c-offices-map__link--kyushu:hover) {
			background-image: url(/assets/images/img_offices_map_kyusyu_hv.png);
		}

		&:has(.c-offices-map__link--hokkaido:hover) {
			background-image: url(/assets/images/img_offices_map_hokkaido_hv.png);
		}

		&:has(.c-offices-map__link--tokai:hover) {
			background-image: url(/assets/images/img_offices_map_tokai_hv.png);
		}
	}
}
