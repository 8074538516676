@use '../../mixin' as m;
/*----------
	remarks
----------*/
$_root: '.c-remarks';

.c-remarks {
	margin-bottom: 20px;
	font-size: 1.4rem;
	line-height: var(--line-height-m);

	&__item {
		padding-left: 1.5em;
		text-indent: -.75em;

		&::before {
			content: '※';
			display: inline-block;
			margin-right: .5em;
		}
	}

	&__item + &__item {
		margin-top: 5px;
	}

	&--order {
		counter-reset: orderlist;

		#{$_root}__item {
			padding-left: 3em;
			text-indent: -3em;

			&::before {
				counter-increment: orderlist;
				content: '※'counter(orderlist);
				min-width: 2em;
				margin-right: 1em;
				text-align: right;
			}
		}
	}

	&--caption {
		font-size: 1.1rem;
		color: var(--color-txt-caption);
	}

	@include m.mq-pc {
		font-size: 1.6rem;

		&--caption {
			font-size: 1.2rem;
		}
	}
}
