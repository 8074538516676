@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	related-link
----------*/
$_root: '.c-related-link';

.c-related-link {
	display: flex;
	flex-wrap: wrap;
	gap: 14px;

	&__item {
		width: calc(50% - 7px);
	}

	&__item-inner {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		color: var(--color-txt);

		&::after {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			margin: 0 auto;
			background-color: var(--color-border);
		}
	}

	&__img {
		flex-shrink: 0;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__textarea {
		position: relative;
		flex-grow: 1;
		min-height: 70px;
		padding: 18px 25px 18px 0;
	}

	&__title {
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 2;
	}

	&__icon {
		position: absolute;
		right: 0;
		top: calc(50% - 9px);
		display: block;
		width: 18px;
		height: 18px;
		border: solid 1px var(--color-prim);
		font-size: .7rem;
		color: inherit;
		line-height: 16px;
		text-align: center;
	}

	@include m.mq-pc {
		gap: 40px 0;

		&__item {
			width: 33.33%;
		}

		&__item-inner {
			&::after {
				width: calc(100% - 40px);
			}

			&[href] {
				&:hover {
					&::after {
						background-color: var(--color-prim);
					}

					#{$_root}__title {
						text-decoration: underline;
					}

					#{$_root}__icon {
						background-color: var(--color-prim);
						color: #ffffff;
					}
				}
			}
		}

		&__textarea {
			min-height: 76px;
			padding: 25px 65px 25px 20px;
		}

		&__icon {
			right: 20px;
			top: calc(50% - 16px);
			width: 32px;
			height: 32px;
			font-size: 1.2rem;
			line-height: 30px;
		}

		&--4up {
			#{$_root}__item {
				width: 25%;
			}
		}

		// pcでitem間のスペースを空ける
		&--spaced {
			gap: 40px;

			#{$_root}__item {
				width: calc(33.33% - 26.66px);
			}

			#{$_root}__item-inner {
				&::after {
					width: 100%;
				}
			}

			#{$_root}__textarea {
				padding: 25px 50px 25px 0;
			}

			#{$_root}__icon {
				right: 0;
			}

			&#{$_root}--4up {
				gap: 40px 36px;

				#{$_root}__item {
					width: calc(25% - 27px);
				}

				#{$_root}__textarea {
					min-height: 70px;
					padding: 20px 35px 20px 0;
				}

				#{$_root}__icon {
					top: calc(50% - 9px);
					width: 18px;
					height: 18px;
					font-size: .7rem;
					line-height: 16px;
				}
			}
		}
	}
}