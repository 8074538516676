@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	important-info
----------*/
$_root: '.c-important-info';

.c-important-info {
	padding: 16px 16px 16px 48px;
	border: 1px solid var(--color-caution);
	position: relative;

	&::before {
		@include m.iconfont-default;
		content: var(--icon-exclamation);
		display: block;
		width: 16px;
		height: 16px;
		position: absolute;
		top: 18px;
		left: 16px;
		font-size: 16px;
	}

	&__item + &__item {
		margin-top: 16px;
	}

	&__date {
		font-size: 1.4rem;
		line-height: var(--line-height-s);
		color: var(--color-txt-caption);
	}

	&__content {
		margin-top: 8px;
		line-height: var(--line-height-s);
		color: var(--color-caution);
		font-size: 1.4rem;

		a {
			color: var(--color-caution);
			text-decoration: underline;
		}
	}

	@include m.mq-pc {
		&__item {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
		}

		&__date {
			flex: 0 0 6.5em;
		}

		&__content {
			margin-top: -.15em;
		}
	}
}
