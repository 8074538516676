@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	news list
----------*/

.g-news-container {
	&__item {
		border-bottom: 1px solid var(--color-border);
	}
}
