@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-quaternary
----------*/

.c-heading-quaternary {
	position: relative;
	margin: 40px 0 20px;
	padding-left: 25px;
	font-weight: bold;
	font-size: 2rem;
	line-height: var(--line-height-m);

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: calc(1em * 1.6 / 2);
		display: block;
		width: 16px;
		height: 1px;
		background: linear-gradient(to right, var(--color-seco1) 10px, var(--color-seco2) 10px);
	}

	@include m.mq-pc {
		margin: 60px 0 20px;
		font-size: 2.4rem;
	}
}
