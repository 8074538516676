@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	tel-number
----------*/
.c-tel-number {
	font-size: 2rem;
	font-weight: bold;
	font-family: Inter, var(--font-sans);
	color: var(--color-txt);

	> a {
		color: var(--color-txt);
	}

	@include m.mq-pc {
		font-size: 2.4rem;
	}
}
