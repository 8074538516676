@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-primary
----------*/

.c-heading-primary {
	font-weight: bold;
	font-size: 3.2rem;
	line-height: 1.5;

	@include m.mq-pc {
		font-size: 4rem;
	}
}
