@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	link-block
----------*/
$_root: '.c-link-block';

.c-link-block {

	&__item {
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&__item-inner {
		position: relative;
		display: block;
		height: 100%;
		color: var(--color-txt);

		&::after {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			margin: 0 auto;
			background-color: var(--color-prim);
			opacity: .2;
		}
	}

	&__img {
		// margin: 0 -20px 25px 0;
		margin-bottom: 25px;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__textarea {
		padding-bottom: 64px;
	}

	&__title {
		margin: 14px 0;
		font-size: 2rem;
		font-weight: 500;
		line-height: var(--line-height-s);
	}

	&__text {
		font-size: 1.4rem;
		font-weight: 500;
		line-height: var(--line-height-s);
	}

	&__icon {
		position: absolute;
		right: 0;
		bottom: 20px;
		display: block;
		width: 28px;
		height: 28px;
		border: solid 1px var(--color-prim);
		line-height: 26px;
		color: inherit;
		font-size: 1rem;
		text-align: center;
	}

	// 黒背景エリアで使用
	&--black-bg {
		#{$_root}__item-inner {
			color: #ffffff;

			&::after {
				background-color: #ffffff;
				opacity: .1;
			}
		}

		#{$_root}__icon {
			border-color: #ffffff;
		}
	}

	// スマホで各itemの下マージン40px
	&--sp-mb-40 {
		#{$_root}__item {
			&:not(:last-child) {
				margin-bottom: 40px;
			}
		}
	}

	// スマホで各itemの下マージン60px
	&--sp-mb-60 {
		#{$_root}__item {
			&:not(:last-child) {
				margin-bottom: 60px;
			}
		}
	}

	@include m.mq-pc {
		display: flex;
		flex-wrap: wrap;
		gap: 60px 0;

		&__item {
			width: 50%;

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}

		&__item-inner {
			&::after {
				width: calc(100% - 80px);
			}
		}

		&__img {
			// margin: 0 0 30px;
			margin-bottom: 30px;
		}

		&__textarea {
			margin: 0 40px;
			padding-bottom: 68px;
		}

		&__icon {
			right: 40px;
			bottom: 24px;
			width: 32px;
			height: 32px;
			font-size: 1.2rem;
			line-height: 30px;
		}

		a:hover {
			&::after {
				opacity: 1;
			}

			#{$_root}__title {
				text-decoration: underline;
			}

			#{$_root}__icon {
				background-color: var(--color-prim);
				color: #ffffff;
			}
		}

		// 黒背景エリアで使用
		&--black-bg {
			a:hover {
				#{$_root}__icon {
					background-color: #ffffff;
					color: var(--color-txt);
				}
			}
		}

		// PCで各item間のスペースを空ける
		&--spaced {
			gap: 60px 40px;

			#{$_root}__item {
				width: calc(50% - 20px);
			}
		}

		// スマホで各itemの下マージン打ち消し
		&--sp-mb-40,
		&--sp-mb-60 {
			#{$_root}__item {
				&:not(:last-child) {
					margin-bottom: 0;
				}
			}
		}
	}
}