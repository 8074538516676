@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	google map
----------*/
.c-map {
	overflow: hidden;
	width: 100%;
	height: 233px;

	iframe {
		display: block;
		width: 100%;
		height: 233px;
		border: 0;
	}

	@include m.mq-pc {
		height: 354px;

		iframe {
			height: 354px;
		}
	}
}