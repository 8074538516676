@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	outline-list
----------*/
.c-outline-list {
	display: flex;
	flex-wrap: wrap;

	&__year {
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 56px;
		height: 56px;
		background-color: #ffffff;
		border: solid 1px #B5B5B5;
		font-family: Inter, var(--font-sans);
		font-size: 1.4rem;
		font-weight: 600;

		&::before {
			content: '';
			position: absolute;
			left: -1px;
			top: -1px;
			display: block;
			width: 11px;
			height: 4px;
			background: linear-gradient(to right, var(--color-seco1) 8px, var(--color-seco2) 8px);
		}
	}

	&__event {
		position: relative;
		width: calc(100% - 56px);
		padding: 0 0 40px 16px;

		&::before {
			content: '';
			position: absolute;
			left: -28px;
			top: 56px;
			display: block;
			width: 3px;
			height: calc(100% - 56px);
			background: url(/assets/images/bg_line_outline_list.png) repeat-y 0 0 / 3px auto;
		}

		&:last-child {
			&::before {
				height: calc(100% - 56px - 10px);
			}

			&::after {
				content: '';
				position: absolute;
				left: -32.5px;
				bottom: 0;
				display: block;
				width: 0;
				height: 0;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 10px solid #1E2380;
				margin: auto;
			}
		}
	}

	&__title {
		margin-bottom: 20px;
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.5;
	}

	&__description {
		> p:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	@include m.mq-pc {
		&__year {
			width: 100px;
			height: 100px;
			font-size: 2rem;

			&::before {
				width: 18px;
				height: 8px;
				background: linear-gradient(to right, var(--color-seco1) 12px, var(--color-seco2) 12px);
			}
		}

		&__event {
			width: calc(100% - 100px);
			padding: 0 0 40px 60px;

			&::before {
				left: -48px;
				top: 100px;
				height: calc(100% - 100px);
			}

			&:last-child {
				&::before {
					height: calc(100% - 100px - 10px);
				}

				&::after {
					left: -52.8px;
				}
			}
		}

		&__title {
			font-size: 2.4rem;
		}

		&__description {
			> p:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}
