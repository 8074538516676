@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	textlink-v2（下線なし）
----------*/
$_root: '.c-textlink-v2';

.c-textlink-v2 {
	margin-bottom: 10px;

	> a {
		color: var(--color-txt);

		&::after {
			@include m.iconfont-default;
			content: m.icon('arrow1-r');
			display: inline-block;
			margin-left: 8px;
			font-size: 1.6rem;
			line-height: 1;
			text-align: center;
		}

		// 別窓のアイコン
		&[target='_blank']::after {
			content: m.icon('blank');
		}

		// PDFのアイコン
		&[href$='.pdf']::after {
			content: m.icon('pdf');
		}
	}

	@include m.mq-pc {
		margin-bottom: 20px;

		> a {
			position: relative;
			box-shadow: 0 1px 0 0 transparent;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}

			&::after {
				position: relative;
				top: 1px;
				margin-left: 16px;
			}
		}
	}
}