@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	deflist
----------*/
$_root: '.c-deflist';

.c-deflist {
	&__body {
		border-top: 1px solid var(--color-border);
	}

	&__term {
		padding: 20px;
		background-color: var(--color-bg);
		font-size: 1.6rem;
		font-weight: bold;
	}

	&__detail {
		padding: 20px 16px;
		background-color: #fff;
		font-size: 1.6rem;
	}

	@include m.mq-pc {
		border-bottom: 1px solid var(--color-border);

		&__body {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
		}

		&__term {
			flex: 0 0 210px;
			padding: 21px 20px;
		}

		&__detail {
			flex: 1 1 auto;
			margin-left: -1px;
			padding: 21px 16px 21px 52px;
		}

		// dt横幅190px固定
		&--dt190 {
			#{$_root}__term {
				flex: 0 0 190px;
			}
		}
	}
}
