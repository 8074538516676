@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	tag
----------*/

.c-tag {
	display: inline-block;
	vertical-align: middle;
	color: var(--color-txt);
	font-weight: 500;
	border-color: var(--color-prim);

	> span {
		overflow: hidden;
		display: block;
		height: 19px;
		max-width: 100%;
		min-width: 64px;
		padding: 0 8px;
		background-color: #fff;
		border: 1px solid;
		border-color: inherit;
		color: inherit;
		font-size: 1rem;
		line-height: 17px;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	@include m.mq-pc {
		> span {
			height: 25px;
			min-width: 80px;
			font-size: 1.2rem;
			line-height: 22px;
		}
	}
}
