@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-quinary
----------*/

.c-heading-quinary {
	margin: 20px 0;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: var(--line-height-l);

	@include m.mq-pc {
		margin: 30px 0 20px;
		font-size: 1.6rem;
	}
}
