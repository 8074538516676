@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	image link
----------*/
$_root: '.c-imagelink';

.c-imagelink {
	&__inner {
		height: 100%;
		display: block;
	}

	&__image {
		margin-bottom: 14px;
	}

	&__title {
		font-weight: bold;
		font-size: 1.6rem;
		line-height: var(--line-height-l);
	}

	&__text {
		margin-top: 12px;
		font-size: 1.4rem;
		color: var(--color-txt);
		line-height: var(--line-height-l);
	}

	&__caption {
		margin-top: 12px;
		font-size: 1.1rem;
		color: var(--color-txt-caption);
		line-height: var(--line-height-l);
	}

	@include m.mq-pc {
		&__inner {
			&[href]:hover {
				#{$_root}__title {
					text-decoration: underline;
				}
			}
		}

		&__image {
			margin-bottom: 16px;
		}

		&__text {
			font-size: 1.6rem;
			line-height: 2;
		}

		&__caption {
			margin-top: 15px;
			font-size: 1.4rem;
			line-height: var(--line-height-m);
		}
	}
}
