@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	anchor
----------*/
$_root: '.c-tab';

.c-tab {

	&__tab {
		display: flex;
		flex-wrap: wrap;

		&-item {
			width: 50%;
			margin-left: -1px;
			margin-top: -1px;
			border: 1px solid var(--color-border2);
			font-weight: 500;
			color: var(--color-txt);

			@include m.mq-pc {
				overflow: hidden;
				width: 190px;
				transition: background-color .2s, color .2s;

				&:hover {
					z-index: 1;
					background-color: var(--color-prim);
					border-color: var(--color-prim);
					color: #ffffff;
				}
			}

			&.is-active {
				z-index: 1;
				background: var(--color-prim);
				border-color: var(--color-prim);
				color: #ffffff;
				pointer-events: none;
				cursor: default;
			}
		}

		&-link {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			min-height: 44px;
			padding: 6px 10px;
			color: inherit;
			font-size: 1.3rem;
			line-height: var(--line-height-s);
			text-align: center;

			@include m.mq-pc {
				font-size: 1.4rem;
			}
		}

	}

	&__contents {
		&-item {
			display: none;

			&.is-active {
				display: block;
			}
		}
	}
}
