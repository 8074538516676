@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	image scaling
----------*/

.c-image-scaling {
	display: block;
	margin-bottom: 20px;

	&::before {
		font-size: 2rem;
		vertical-align: middle;
	}

	&::after {
		content: '画像は拡大できます';
		display: inline-block;
		font-size: 1.2rem;
		vertical-align: middle;
		margin-left: 8px;
	}

	@include m.mq-pc {
		display: none;
	}
}
