@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	news
----------*/
$_root: '.c-news';

.c-news {
	&__detail {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto;
		padding: 17px 0;
	}

	&__date {
		grid-column: 1;
		grid-row: 1;
		margin-right: 21px;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.3;
		vertical-align: middle;
	}

	&__tag {
		grid-column: 2;
		grid-row: 1;
	}

	&__headline {
		position: relative;
		grid-column: 1/3;
		grid-row: 2;
		font-size: 1.4rem;
		line-height: var(--line-height-l);
	}

	&__title {
		&::after {
			content: '';
			@include m.iconfont-default;
			display: none;
			font-size: 1.2rem;
			line-height: 1;
			text-align: center;
		}
	}

	> a {
		display: block;
		color: var(--color-txt);

		// 別窓のアイコン
		&[target='_blank'] #{$_root}__title::after {
			content: m.icon('blank');
			display: inline-block;
		}

		// PDFのアイコン
		&[href$='.pdf'] #{$_root}__title::after {
			content: m.icon('pdf');
			display: inline-block;
			font-size: 1.6rem;
		}
	}


	@include m.mq-sp {
		&__date {
			margin-bottom: 8px;
		}

		&__tag {
			margin-bottom: 8px;
		}
	}

	@include m.mq-pc {
		&__detail {
			grid-template-columns: auto auto 1fr;
			grid-template-rows: auto;
			padding-top: 16px;
			padding-bottom: 16px;
		}

		&__date {
			margin-right: 25px;
			font-size: 1.6rem;
			line-height: var(--line-height-m);
		}

		&__tag {
			grid-column: 2;
			grid-row: 1;
			margin-top: 2px;
			margin-right: 12px;
		}

		&__headline {
			grid-column: 3;
			grid-row: 1;
			font-size: 1.6rem;
		}

		&__title {
			&::after {
				margin-left: 8px;
			}
		}

		> a {
			&:hover {
				background-color: var(--color-bg);

				#{$_root}__title {
					text-decoration: underline;
				}
			}
		}
	}
}
