@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button
----------*/
$_root: '.c-button';

// stylelint-disable no-duplicate-selectors
.c-button {

	&__inner {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: 56px;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;
		background-color: #FFFFFF;
		border: solid 1px var(--color-prim);
		color: var(--color-text);
		cursor: pointer;

		> span {
			padding: 8px 40px;
			text-align: center;
			font-size: 1.6rem;
			font-weight: 500;
			line-height: var(--line-height-m);
			color: inherit;
		}
	}

	&__icon {
		width: 1.2rem;
		height: 1.2rem;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 15px;
		line-height: 1;
		color: inherit;
		font-size: 1.2rem;
	}

	@include m.mq-pc {
		&__inner {
			min-height: 64px;
			transition: background-color .2s, color .2s;

			&:hover {
				background-color: var(--color-prim);
				color: #FFFFFF;
			}
		}

		&__icon {
			width: 1.6rem;
			height: 1.6rem;
			right: 20px;
			font-size: 1.6rem;
		}
	}
}

/* link icon */
.c-button {
	&--link-icon {
		#{$_root}__inner {
			&::after {
				@include m.iconfont-default;
				content: m.icon('arrow2-r');
				width: 1.2rem;
				height: 1.2rem;
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 15px;
				line-height: 1;
				color: inherit;
				font-size: 1.2rem;
			}

			// 別窓のアイコン
			&[target='_blank']::after {
				content: m.icon('blank');
			}

			// PDFのアイコン
			&[href$='.pdf']::after {
				content: m.icon('pdf');
			}
		}

		@include m.mq-pc {
			#{$_root}__inner {
				&::after {
					width: 1.6rem;
					height: 1.6rem;
					right: 20px;
					font-size: 1.6rem;
				}
			}
		}
	}
}

/* color */
.c-button {
	&--black {
		#{$_root}__inner {
			background-color: var(--color-prim);
			color: #FFFFFF;
		}
	}

	@include m.mq-pc {
		&--black {
			#{$_root}__inner {
				&:hover {
					background-color: var(--color-bg);
					color: var(--color-txt);
				}
			}
		}
	}
}

/* お問い合せ */
.c-button {
	&--inquiry {
		#{$_root}__icon {
			width: 1.8rem;
			height: 1.8rem;
			right: auto;
			left: 18px;
			font-size: 1.8rem;
		}
	}
	@include m.mq-pc {
		&--inquiry {
			#{$_root}__icon {
				width: 2.4rem;
				height: 2.4rem;
				right: auto;
				left: 24px;
				font-size: 2.4rem;
			}
		}
	}
}

/* tel */
.c-button {
	&--tel {
		#{$_root}__inner {
			min-height: 72px;

			> span {
				line-height: 1.2;
				font-size: 1.4rem;
			}
		}

		#{$_root}__number {
			display: block;
			margin-top: 2px;
			font-family: Inter, var(--font-sans);
			font-size: 2.6rem;
			font-weight: bold;
		}

		#{$_root}__icon {
			width: 2.25rem;
			height: 2.25rem;
			right: auto;
			left: 15px;
			font-size: 2.25rem;
		}
	}
	@include m.mq-pc {
		&--tel {
			#{$_root}__inner {
				min-height: 72px;
			}

			#{$_root}__icon {
				width: 3rem;
				height: 3rem;
				right: auto;
				left: 23px;
				font-size: 3rem;
			}
		}
	}
}

/* map */
.c-button {
	&--map {
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;

		#{$_root}__inner {
			min-height: 48px;

			> span {
				padding: 0;
				font-size: 1.4rem;
				font-weight: 400;
			}
		}

		#{$_root}__icon-map {
			position: relative;
			top: 1px;
			left: 0;
			margin-right: 6px;
			font-size: 1.6rem;
		}
	}

	@include m.mq-pc {
		&--map {
			max-width: none;
			width: 150px;
			margin: 0;

			#{$_root}__inner {
				min-height: 40px;

				> span {
					padding: 0;
				}
			}
		}
	}
}
// stylelint-enable no-duplicate-selectors