@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	feature
----------*/
$_root: '.c-card-feature';

.g-card-feature-container {
	margin: 0 auto;

	@include m.mq-pc {
		display: grid;
		gap: 0;
		width: auto;

		&--2up {
			grid-template-columns: repeat(2, 1fr);
		}

		&--3up {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
