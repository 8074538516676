@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	article-header
----------*/
.c-article-header {
	margin: 40px 0;
	text-align: right;

	> span {
		display: block;
	}

	@include m.mq-pc {
		margin-top: 80px;
		margin-bottom: 85px;
	}
}
