@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	caption
----------*/

.c-caption {
	margin-bottom: 20px;
	font-size: 1.1rem;
	line-height: var(--line-height-m);
	color: var(--color-txt-caption);

	&:last-child {
		margin-bottom: 0;
	}

	> a {
		text-decoration: underline;
		color: var(--color-txt-caption);
	}

	@include m.mq-pc {
		font-size: 1.2rem;

		> a {
			transition: opacity .2s;

			&:hover {
				opacity: .7;
			}
		}
	}
}
