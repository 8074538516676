@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	text
----------*/

.c-text {
	margin-bottom: 20px;
	font-size: 1.4rem;
	line-height: var(--line-height-l);
	overflow-wrap: break-word;

	&:not(p) {
		&:is(div) > p {
			margin-bottom: 1.8em;
		}

		> p:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: var(--color-txt);
		text-decoration: underline;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&--bold {
		font-weight: bold;
	}

	@include m.mq-pc {
		font-size: 1.6rem;

		a {
			transition: opacity .2s;

			&:hover {
				opacity: .7;
			}
		}
	}
}
