@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	imageset
----------*/

.c-imageset {

	&__image {
		margin-bottom: 26px;
	}

	&__content {
		*:first-child {
			margin-top: 0;
		}

		*:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		margin-bottom: 20px;
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 1.5;
	}

	&__text {
		font-size: 1.4rem;
		line-height: var(--line-height-l);
	}

	&__textlink {
		max-width: 350px;
		margin-top: 35px;
	}

	&__caption {
		margin-top: 15px;
	}

	@include m.mq-pc {
		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		&__image {
			flex: 0 0 calc(50% - 20px);
			margin-right: 40px;
			margin-bottom: 0;
		}

		&__content {
			flex: 1 0 calc(50% - 20px);
		}

		&__title {
			margin-bottom: 16px;
			font-size: 2rem;
			line-height: 2;
		}

		&__text {
			font-size: 1.6rem;
			line-height: 2;
		}

		&__textlink {
			max-width: 333px;
			margin-top: 36px;
		}

		&--1t2 {
			.c-imageset__image {
				flex-basis: calc(33.3333% - 20px);
			}
		}

		&--1t3 {
			.c-imageset__image {
				flex-basis: calc(25% - 20px);
			}
		}

		&--fixed-273 {
			.c-imageset__image {
				flex-basis: calc(293px - 20px);
			}
		}

		&--reverse {
			.c-imageset__inner {
				flex-direction: row-reverse;
			}

			.c-imageset__image {
				margin-right: 0;
				margin-left: 32px;
			}
		}
	}
}
