@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	list
----------*/

.c-list {
	margin-bottom: 20px;
	margin-left: 5px;
	font-size: 1.4rem;
	line-height: var(--line-height-m);

	&__item {
		position: relative;
		padding-left: 1em;

		&::before {
			content: '';
			position: absolute;
			left: .1em;
			top: .7em;
			display: inline-block;
			width: .2em;
			height: .2em;
			background-color: var(--color-txt);
			border-radius: 50%;
		}
	}

	&__item + &__item {
		margin-top: 5px;
	}

	@include m.mq-pc {
		font-size: 1.6rem;
	}
}
