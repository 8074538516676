@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	feature
----------*/
$_root: '.c-card-feature';

.c-card-feature {

	&__inner {
		display: block;
		height: 100%;
		position: relative;
		background-color: #ffffff;
		color: #ffffff;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 45%;
			position: absolute;
			bottom: 0;
			z-index: 1;
			background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .75) 45.32%, rgba(0, 0, 0, .9) 100%);
			opacity: .9;
		}
	}

	&__image {
		width: 100%;
	}

	&__content {
		width: 100%;
		padding: 0 16px 16px;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
	}

	&__title {
		font-size: 1.6rem;
		font-weight: 500;
		line-height: var(--line-height-m);
	}

	&__icon {
		position: absolute;
		bottom: 14px;
		right: 14px;
		width: 28px;
		height: 28px;
		border: solid 1px #ffffff;
		font-size: 1rem;
		line-height: 26px;
		text-align: center;
	}

	> a {
		color: #ffffff;

		&__content {
			padding-right: 52px;
		}
	}

	@include m.mq-pc {
		&__inner {
			&::before {
				height: 38%;
			}
		}

		&__content {
			padding: 0 20px 20px;
		}

		&__title {
			font-size: 1.8rem;
		}

		&__icon {
			bottom: 18px;
			right: 17px;
			width: 32px;
			height: 32px;
			font-size: 1.2rem;
			line-height: 30px;
		}

		> a {
			&__content {
				padding-right: 60px;
			}

			&:hover {
				#{$_root}__title {
					text-decoration: underline;
				}

				#{$_root}__icon {
					background-color: #ffffff;
					color: var(--color-txt);
				}
			}
		}
	}
}
