@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	figure
----------*/
$_root: '.c-figure';

.c-figure {
	&__image {
		text-align: center;

		> img {
			width: auto;
			max-width: 100%;
		}
	}

	&__caption {
		margin-top: 20px;
		text-align: left;
		font-size: 1.2rem;
		line-height: var(--line-height-m);
		color: var(--color-txt-caption);

		&--note {
			font-size: 1.2rem;
			text-align: right;
		}
	}

	@include m.mq-pc {
		&__caption {
			font-size: 1.4rem;
		}
	}
}
