@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	cap
----------*/

.c-cap {
	margin-bottom: 80px;
	padding: 30px 40px;
	background-color: var(--color-bg);
	font-size: 1.8rem;
	font-weight: 500;
	line-height: var(--line-height-l);
	text-align: center;

	@include m.mq-pc {
		margin-bottom: 80px;
		padding: 60px 40px;
		font-size: 2.4rem;
		line-height: 1.5;
	}
}
