@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	textlink-v3（サイトマップなど階層構造用）
----------*/
$_root: '.c-textlink-v3';

.c-textlink-v3 {
	&:last-child {
		margin-bottom: 0;
	}

	&__inner {
		position: relative;
		display: block;
		padding-bottom: 30px;
		padding-right: 48px;
		border-bottom: solid 1px #d2d2d2;
		color: var(--color-txt);
	}

	&__title {
		font-size: 1.6rem;
		font-weight: 500;
		line-height: var(--line-height-m);
	}

	&__icon {
		position: absolute;
		right: 0;
		top: .2em;
		display: block;
		width: 24px;
		height: 24px;
		border: solid 1px var(--color-prim);
		line-height: 22px;
		color: inherit;
		font-size: .9rem;
		text-align: center;

		&.icon-pdf {
			font-size: 1rem;
		}
	}

	&--lv1 {
		margin-bottom: 40px;

		#{$_root}__inner {
			display: flex;
			align-items: center;
			min-height: 92px;
			padding: 20px 52px 20px 0;
		}

		#{$_root}__title {
			font-size: 2rem;
		}

		#{$_root}__icon {
			top: calc(50% - 16px);
			width: 32px;
			height: 32px;
			line-height: 30px;
			font-size: 1.2rem;
		}
	}

	&--lv2 {
		margin-bottom: 40px;

		#{$_root}__inner {
			margin-left: 30px;
		}
	}

	&--lv3 {
		margin-bottom: 24px;

		#{$_root}__inner {
			margin-left: 70px;
			padding: 5px 48px 5px 0;
			border-bottom: none;
		}

		#{$_root}__title {
			font-size: 1.4rem;
		}

		#{$_root}__icon {
			top: calc(50% - 10px);
			width: 20px;
			height: 20px;
			line-height: 18px;
			font-size: .75rem;
		}
	}

	&--lv4 {
		margin-bottom: 24px;

		#{$_root}__inner {
			margin-left: 104px;
			padding: 5px 48px 5px 0;
			border-bottom: none;
		}

		#{$_root}__title {
			font-size: 1.4rem;
		}

		#{$_root}__icon {
			top: calc(50% - 10px);
			width: 20px;
			height: 20px;
			line-height: 18px;
			font-size: .75rem;
		}
	}

	&--lv2 + &--lv3 {
		margin-top: -16px;
	}

	&--lv3 + &--lv2 {
		margin-top: 40px;
	}

	@include m.mq-pc {
		> a {
			height: 100%;

			#{$_root}__icon {
				transition: background-color .2s, color .2s;
			}

			&:hover {
				border-color: var(--color-txt);

				#{$_root}__title {
					text-decoration: underline;
				}

				#{$_root}__icon {
					background-color: var(--color-prim);
					color: #ffffff;
				}
			}
		}

		&--lv2 {
			#{$_root}__inner {
				margin-left: 70px;
			}
		}

		&--lv3 {
			margin-bottom: 20px;

			#{$_root}__inner {
				margin-left: 170px;
			}
		}

		&--lv4 {
			margin-bottom: 24px;

			#{$_root}__inner {
				margin-left: 210px;
			}
		}

		&--lv2 + &--lv3 {
			margin-top: 0;
		}

		&--lv3 + &--lv2 {
			margin-top: 40px;
		}

	}
}