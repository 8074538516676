@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	icontext
----------*/

.c-icontext {
	> a,
	> span {
		display: table;
		padding-left: 28px;
		text-indent: -28px;
	}

	> a {
		text-decoration: underline;
		color: var(--color-txt);
	}

	i[class^='icon-'] {
		text-indent: 0;
		margin-right: 12px;
		font-size: 1.6rem;
		transform: translateY(.15em);
		color: var(--color-txt);
	}

	@include m.mq-pc {
		> a {
			transition: opacity .2s;

			&:hover {
				opacity: .7;
			}
		}
	}
}
