@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	lead
----------*/

.c-lead {
	position: relative;
	padding-bottom: 34px;
	font-size: 2.2rem;
	font-weight: 500;
	line-height: var(--line-height-m);
	text-align: center;

	&::after {
		content: '';
		position: absolute;
		left: calc(50% - 15px);
		bottom: 0;
		display: block;
		width: 30px;
		height: 4px;
		background: linear-gradient(to right, var(--color-seco1) 22px, var(--color-seco2) 22px);
	}

	@include m.mq-pc {
		font-size: 3.2rem;
		padding-bottom: 42px;
	}
}
