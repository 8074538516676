@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	table
----------*/
$_root: '.c-table';

.c-table {
	&__content {
		width: 100%;
	}

	&__header {
		padding: 21px 20px;
		background-color: var(--color-bg);
		border-top: 1px solid var(--color-border);
		font-weight: bold;
		text-align: left;
		white-space: nowrap;
	}

	&__cell {
		padding: 21px 16px 21px 52px;
		background-color: #fff;
		border-top: 1px solid var(--color-border);
	}

	// th 210px固定
	&--t210 {
		#{$_root}__header {
			width: 210px;
			white-space: normal;
		}
	}

	// th 190px固定
	&--t190 {
		#{$_root}__header {
			width: 190px;
			white-space: normal;
		}
	}

	@include m.mq-pc {
		border-bottom: 1px solid var(--color-border);

		// th 改行なし
		&--th-nowrap {
			#{$_root}__header {
				white-space: nowrap;
			}
		}
	}

	@include m.mq-sp {
		&__content {
			> tbody > tr,
			> thead > tr,
			> tfoot > tr,
			> tr,
			> tbody > tr > td,
			> tbody > tr > th,
			> thead > tr > th,
			> tfoot > tr > td,
			> tr > td,
			> tr > th {
				display: block;
			}
		}

		&__header {
			padding: 20px;
			font-size: 1.6rem;
			white-space: normal;
		}

		&__cell {
			padding: 20px 16px;
			border-top: none;
			font-size: 1.6rem;
		}

		// th 210px固定
		&--t210 {
			#{$_root}__header {
				width: 100%;
			}
		}

		// th 190px固定
		&--t190 {
			#{$_root}__header {
				width: 100% !important;
			}
		}
	}
}
