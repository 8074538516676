@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	breadcrumb
----------*/
$_root: '.c-breadcrumb';

.c-breadcrumb {
	text-align: left;

	&__list {
		display: inline-flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		font-size: 1.2rem;
		line-height: 1.2;

		> li {
			display: block;

			> a {
				text-decoration: none;
				color: var(--color-txt);
			}

			+ li {
				&::before {
					content: '>';
					display: inline-block;
					margin: 0 6px 0 9px;
				}
			}
		}
	}

	@include m.mq-sp {
		margin: 0 -20px;

		&__inner {
			overflow-x: auto;
			width: 100%;
			padding: 0 20px;
		}
	}

	@include m.mq-pc {
		text-align: right;

		&__list {
			display: block;
			white-space: normal;
			font-size: 1.2rem;

			> li {
				display: inline;

				> a {
					transition: box-shadow .2s;

					&:hover {
						box-shadow: 0 1px 0 0 var(--color-txt);
					}
				}
			}
		}
	}
}