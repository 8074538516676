@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	table
----------*/
$_root: '.c-table-v2';

.c-table-v2 {
	&__content {
		width: 100%;
		border-top: #7D7E81 solid 1px;
		line-height: var(--line-height-l);
		text-align: left;
	}

	&__header {
		padding: 21px 20px;
		background-color: var(--color-bg);
		border-bottom: 1px solid #D7D8DE;
		font-weight: bold;
		white-space: nowrap;
		vertical-align: top;
	}

	&__cell {
		padding: 21px 20px;
		background-color: #fff;
		border-bottom: 1px solid #D7D8DE;
	}

	&__month-info {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	&__month {
		width: 60px;
		padding-right: 20px;
		font-weight: bold;
		text-align: right;
	}

	&__text {
		width: calc(100% - 60px);
		margin-bottom: 1.8em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include m.mq-sp {
		&__content {
			border-top: none;

			> tbody > tr,
			> thead > tr,
			> tfoot > tr,
			> tbody > tr > td,
			> tbody > tr > th,
			> thead > tr > th,
			> tfoot > tr > td,
			> tr,
			> tr > td,
			> tr > th {
				display: block;
			}
		}

		&__header {
			padding: 20px 16px;
			border-top: #7D7E81 solid 1px;
			border-bottom: 1px solid var(--color-border);
		}

		&__cell {
			padding: 20px 16px;
			border-bottom: none;
		}

		&__month-info {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}

		&__month {
			width: 67px;
			padding-right: 32px;
		}

		&__text {
			width: calc(100% - 67px);
		}
	}
}