@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	textlink container
----------*/
$_root: '.g-textlink-container';

.g-textlink-container {
	margin: 0 auto;

	&__item {
		max-width: 100%;
	}

	@include m.mq-sp {
		&__item {
			margin: 0 auto 38px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include m.mq-pc {
		&__item {
			margin-left: auto;
			margin-right: auto;
		}

		&__item + &__item {
			margin-top: 57px;
		}

		@supports (display: grid) {
			// 2・3・4カラム以上
			&--2up,
			&--3up,
			&--4up {
				display: grid;
				gap: 57px 40px;

				#{$_root}__item {
					max-width: none;
					margin: 0;
				}
			}

			&--2up {
				grid-template-columns: repeat(2, 1fr);
			}

			&--3up {
				grid-template-columns: repeat(3, 1fr);
			}

			&--4up {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}
}
