@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	group-list-link
----------*/
$_root: '.c-group-list-link';

.c-group-list-link {

	&__inner {
		display: grid;
		grid-template-columns: 1fr;
		gap: 40px;
	}

	&__item {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: 16px;
			top: -1px;
			display: block;
			width: 52px;
			height: 3px;
			background: linear-gradient(to right, var(--color-seco1) 32px, var(--color-seco2) 32px);
		}
	}

	&__item-inner {
		display: block;
		padding: 22px 50px 26px 15px;
		border: solid 1px #D7D8DE;
		color: var(--color-txt);

		&::after {
			@include m.iconfont-default;
			content: m.icon('arrow2-r');
			position: absolute;
			right: 7px;
			bottom: 8px;
			display: block;
			width: 24px;
			height: 24px;
			border: solid 1px var(--color-prim);
			line-height: 22px;
			color: inherit;
			font-size: .9rem;
			text-align: center;
		}

		// 別窓のアイコン
		&[target='_blank']::after {
			content: m.icon('blank');
		}

		// PDFのアイコン
		&[href$='.pdf']::after {
			content: m.icon('pdf');
		}
	}

	&__title {
		font-size: 1.6rem;
		font-weight: bold;
		line-height: var(--line-height-m);
	}

	&__text {
		margin-top: 8px;
		font-size: 1.2rem;
		font-weight: 500;
		line-height: var(--line-height-l);
	}

	@include m.mq-pc {
		&__inner {
			grid-template-columns: 1fr 1fr;
			column-gap: 40px;
			row-gap: 60px;
		}

		&__item {
			height: 100%;

			&::before {
				left: 31px;
			}
		}

		&__item-inner {
			height: 100%;
			padding: 30px 57px 35px 32px;

			&::after {
				right: 9px;
				bottom: 9px;
				width: 32px;
				height: 32px;
				line-height: 30px;
				font-size: 1.2rem;
			}
		}

		&__title {
			font-size: 1.8rem;
			line-height: 1.5;
		}

		&__text {
			font-size: 1.4rem;
		}

		a {
			&:hover {
				border-color: #B9B9B9;

				&::after {
					background-color: var(--color-prim);
					color: #ffffff;
				}

				#{$_root}__title {
					text-decoration: underline;
				}
			}
		}
	}
}