@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-secondary
----------*/

.c-heading-secondary {
	position: relative;
	margin: 80px 0 40px;
	padding-top: 26px;
	font-weight: bold;
	font-size: 2.8rem;
	line-height: 1.5;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 52px;
		height: 4px;
		background: linear-gradient(to right, var(--color-seco1) 32px, var(--color-seco2) 32px);
	}

	@include m.mq-pc {
		margin: 120px 0 60px;
		padding-top: 51px;
		font-size: 3.2rem;

		&::after {
			width: 88px;
			height: 6px;
			background: linear-gradient(to right, var(--color-seco1) 68px, var(--color-seco2) 68px);
		}
	}
}
