@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-tertiary
----------*/

.c-heading-tertiary {
	position: relative;
	margin: 40px 0 24px;
	padding-left: 15px;
	font-weight: bold;
	font-size: 2.4rem;
	line-height: 1.5;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: .05em;
		display: block;
		width: 4px;
		height: 36px;
		background: linear-gradient(to bottom, var(--color-seco1) 21px, var(--color-seco2) 21px);
	}

	@include m.mq-pc {
		margin: 60px 0 40px;
		padding-left: 19px;
		font-size: 2.8rem;

		&::before {
			width: 4px;
			height: 41px;
			background: linear-gradient(to bottom, var(--color-seco1) 26px, var(--color-seco2) 26px);
		}
	}
}
