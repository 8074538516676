@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	grid
----------*/
$_root: '.g-grid';

.g-grid {
	margin: 0 auto;

	@include m.mq-sp {
		&__item + &__item {
			margin-top: 40px;
		}

		&--no-space {
			#{$_root}__item + #{$_root}__item {
				margin-top: 0;
			}
		}

		&--sp-2up {
			display: grid;
			gap: 30px 14px;
			grid-template-columns: repeat(2, 1fr);
			width: auto;

			#{$_root}__item + #{$_root}__item {
				margin-top: 0;
			}
		}
	}

	@include m.mq-pc {
		display: grid;
		gap: 40px;
		width: auto;

		&__item + &__item {
			margin-top: 0;
		}

		&--2up {
			grid-template-columns: repeat(2, 1fr);
		}

		&--3up {
			grid-template-columns: repeat(3, 1fr);
		}

		&--4up {
			grid-template-columns: repeat(4, 1fr);
		}

		&--no-space {
			gap: 0;
		}
	}
}
