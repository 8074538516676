@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	youtube
----------*/
$_root: '.c-youtube ';

.c-youtube {

	&__inner {
		position: relative;

		&::before {
			content: '';
			display: block;
			padding-top: 56.25%;
		}

		> iframe {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	&--w780 {
		max-width: 780px;
		margin: 0 auto;
	}

	@include m.mq-pc {
		&--fixed-width {
			padding: 0 60px;
		}
	}
}
