@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	order list
----------*/
$_root: '.c-orderlist';

.c-orderlist {
	counter-reset: orderlist;
	margin-bottom: 20px;
	font-size: 1.4rem;
	line-height: var(--line-height-m);

	&__item {
		@include m.clrfx;
		display: flex;
		width: 100%;

		&::before {
			counter-increment: orderlist;
			content: counter(orderlist)'.';
			display: block;
			float: left;
			margin-right: .5em;
			text-align: right;
		}
	}

	&__item + &__item {
		margin-top: 5px;
	}

	&__content {
		overflow: hidden;

		> *:first-child {
			margin-top: 5px;
		}
	}

	// ※リスト
	&--remarks {
		#{$_root}__item {
			&::before {
				content: '※'counter(orderlist);
			}
		}
	}

	// 各item間のマージンを空ける
	&--wide-margin {
		#{$_root}__item + #{$_root}__item {
			margin-top: 20px;
		}
	}

	@include m.mq-pc {
		font-size: 1.6rem;

		// 各item間のマージンを空ける
		&--wide-margin {
			#{$_root}__item + #{$_root}__item {
				margin-top: 25px;
			}
		}
	}
}