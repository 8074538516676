@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button container
----------*/
$_root: '.g-button-container';

.g-button-container {
	margin: 0 auto;

	&__item {
		max-width: 450px;
	}

	@include m.mq-sp {
		&__item {
			margin: 0 auto 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include m.mq-pc {
		&__item {
			margin-left: auto;
			margin-right: auto;
		}

		&__item + &__item {
			margin-top: 40px;
		}

		@supports (display: grid) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: stretch;

			#{$_root}__item {
				flex: 1 1 450px;
				margin: 0;
			}

			// 2カラム
			&--2up {
				justify-content: center;

				#{$_root}__item {
					flex: 1 1 450px;

					+ #{$_root}__item {
						margin-left: 40px;
					}
				}
			}

			// カラム寄せ シングル・2カラムモディファイアと併用可
			&--center {
				justify-content: center;
			}

			&--left {
				justify-content: flex-start;
			}

			&--right {
				justify-content: flex-end;
			}

			// 3・4カラム以上
			&--3up,
			&--4up {
				display: grid;
				gap: 40px;

				#{$_root}__item {
					max-width: none;
				}
			}

			&--3up {
				grid-template-columns: repeat(3, 1fr);
			}

			&--4up {
				grid-template-columns: repeat(4, 1fr);
			}

			// 2カラムGrid
			&--2up-grid {
				display: grid;
				gap: 40px;
				max-width: 940px;
				grid-template-columns: repeat(2, 1fr);

				#{$_root}__item {
					max-width: 450px;
				}
			}
		}
	}

	/* ボーダー接合 3カラム（改行左寄せ） */
	&--collapse {
		#{$_root}__item {
			max-width: none;
		}

		@include m.mq-sp {
			#{$_root}__item {
				margin: 0 auto;
			}
		}

		@include m.mq-pc {
			&#{$_root}--2up,
			&#{$_root}--3up,
			&#{$_root}--4up {
				gap: 0 40px;
			}
		}
	}
}
