@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	textlink
----------*/
$_root: '.c-textlink';

.c-textlink {
	&__inner {
		position: relative;
		display: block;
		padding-right: 50px;
		border-bottom: solid 1px #d2d2d2;
		color: var(--color-txt);
	}

	&__title {
		padding-bottom: 24px;
		font-size: 1.6rem;
		font-weight: 500;
		line-height: var(--line-height-m);
	}

	&__icon {
		position: absolute;
		right: 0;
		top: .2em;
		display: block;
		width: 28px;
		height: 28px;
		border: solid 1px var(--color-prim);
		line-height: 26px;
		color: inherit;
		font-size: 1rem;
		text-align: center;
	}

	&__subtext {
		position: relative;
		margin-top: -15px;
		padding-bottom: 28px;
		font-size: 1.4rem;
		line-height: 2;

		#{$_root}__icon {
			right: -50px;
		}
	}

	&--large {
		#{$_root}__title {
			padding-bottom: 22px;
			font-size: 1.8rem;
		}
	}

	&--w373 {
		max-width: 373px;
	}

	&--auto-width {
		display: inline-block;
	}

	// 親コンテナー内は幅指定なし
	.g-textlink-container & {
		max-width: none;
	}

	@include m.mq-pc {
		&__title {
			padding-bottom: 30px;
		}

		&__icon {
			width: 32px;
			height: 32px;
			font-size: 1.2rem;
			line-height: 30px;
		}

		&__subtext {
			padding-bottom: 30px;
		}

		&--large {
			#{$_root}__title {
				padding-bottom: 22px;
				font-size: 2rem;
			}
		}

		&--small {
			#{$_root}__title {
				line-height: 1.5;
			}
		}

		> a {
			height: 100%;

			#{$_root}__icon {
				transition: background-color .2s, color .2s;
			}

			&:hover {
				border-color: var(--color-txt);

				#{$_root}__title {
					text-decoration: underline;
				}

				#{$_root}__icon {
					background-color: var(--color-prim);
					color: #ffffff;
				}
			}
		}
	}
}