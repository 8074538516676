@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	btn-bnr
----------*/
.c-btn-bnr {
	text-align: center;

	&__inner {
		display: inline-block;
		max-height: 88px;

		> img {
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 88px;
		}
	}

	@include m.mq-pc {
		&__inner {
			max-height: 100px;

			> img {
				max-height: 100px;
			}

			&[href] {
				transition: opacity .2s;

				&:hover {
					opacity: .4;
				}
			}
		}
	}
}