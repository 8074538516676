@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	select-filter
----------*/
.c-select-filter {
	display: block;
	cursor: pointer;
	margin-bottom: 30px;

	&__inner {
		display: inline-block;
		position: relative;

		&::after {
			@include m.iconfont-default;
			content: var(--icon-arrow2-b);
			position: absolute;
			top: 50%;
			right: 14px;
			font-size: 1.2rem;
			font-weight: 500;
			transform: translateY(-50%);
			pointer-events: none;
		}

		> select {
			display: block;
			max-width: 100%;
			min-width: 175px;
			padding: 11px 40px 11px 20px;
			border: 1px solid var(--color-border);
			color: var(--color-txt);
			font-size: 1.2rem;
			appearance: none;
			-webkit-appearance: none;

			&::-ms-expand {
				display: none;
			}
		}
	}

	@include m.mq-pc {
		margin-bottom: 40px;

		&__inner {
			&::after {
				right: 12px;
			}

			> select {
				min-width: 190px;
				padding: 11px 40px 11px 20px;
				font-size: 1.4rem;
			}
		}
	}
}
